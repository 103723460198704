import React, {Suspense, useContext} from "react";
import "./twitter.scss";
import Loading from "../loading/Loading";
import {TwitterTimelineEmbed} from "react-twitter-embed";
import {twitterDetails} from "../../portfolio";
import StyleContext from "../../contexts/StyleContext";

const renderLoader = () => <Loading />;
const cantDisplayError =
  "<div className='centerContent'><h2>Can't load? Check privacy protection settings</h2></div>";

function timeOut() {
  setTimeout(function () {
    if (!document.getElementById("twitter").innerHTML.includes("iframe")) {
      document.getElementById("twitter").innerHTML = cantDisplayError;
    }
  }, 10000);
}
var widthScreen = window.screen.width/2;

export default function Twitter() {
  const {isDark} = useContext(StyleContext);

  if (!twitterDetails.display) {
    return null;
  }
  if (!twitterDetails.userName) {
    console.error("Twitter username for twitter section is missing");
  }
  if (twitterDetails.userName) {
    return (
      <Suspense fallback={renderLoader()}>
        <div className="tw-main-div" id="twitter">
          {/* <div className="centerContent">
            <TwitterTimelineEmbed
              sourceType="profile"
              screenName={twitterDetails.userName}
              options={{height: 400, width: {widthScreen}}}
              placeholder={renderLoader()}
              autoHeight={true}
              borderColor="#fff"
              key={isDark ? "1" : "2"}
              theme={isDark ? "dark" : "light"}
              noFooter={true}
              onload={timeOut()}
            />
          </div> */}

          <div className="text-center my-2">
            <p>
            <div style={{color: "grey", padding: "0 20px", "font-family": 'Arial, sans-serif', "font-size": "16px", "line-height": "1.5", "transform": "rotate(-3deg);", "text-align": "center"}}>
  <p>Investing in <span style={{"font-weight": "bold"}}>$CATZ</span> is subject to market risks, including potential loss of principal.</p>
  <p>Cryptocurrency investments are highly volatile and can be influenced by regulatory and technological changes.</p>
  <p>Please conduct thorough research and consult with a financial advisor before making any investment decisions.</p>
  <p>By investing, you acknowledge and accept full responsibility for your actions.</p>
</div>

            </p>
          </div>
        </div>
      </Suspense>
    );
  } else {
    return null;
  }
}
