import React, { useContext } from "react";
import { Fade } from "react-reveal";
import emoji from "react-easy-emoji";
import "./Greeting.scss";
import landingPerson from "../../assets/lottie/game-icon";
import DisplayLottie from "../../components/displayLottie/DisplayLottie";
import SocialMedia from "../../components/socialMedia/SocialMedia";
import Button from "../../components/button/Button";

import { illustration, greeting, tokenomics } from "../../portfolio";
import StyleContext from "../../contexts/StyleContext";

export default function Tokenomics() {
  const { isDark } = useContext(StyleContext);
  if (!greeting.displayGreeting) {
    return null;
  }
  return (
    <Fade bottom duration={1000} distance="40px">
      <div
        className="row"
        style={{height:"800px"}}
      >
        <div
          className="col-md-6 col-sm-12   m-auto"
          style={{ font: "Neue Machina" }}
        >
          <div className="text-left ml-4" style={{ padding: "50px" }}>
            <h1 style={{ fontWeight: "bolder", color: "red" }}>
              {" "}
              <span
                style={{color:"white",fontStyle:"italic",fontFamily:"fresca",fontSize:"5rem"}}
              >
                TOKENOMICS
              </span>
              {/* <span className="wave-emoji">{emoji("🐸")}</span>  */}
            </h1>
            <div className="m-auto">
              <span
                style={{ color: "white", }}
              >
                {" "}
                Total Supply :{" "}
              </span>
              <span style={{ color: "grey", fontWeight:"bolder" }}>
                {tokenomics["Total Token "]}
              </span>
              <br />
              <br />
              <span
                style={{ color: "white", }}
              >
                {" "}
                Liquidity Pool :{" "}
              </span>
              <span style={{ color: "grey",fontWeight:"bolder" }}>
                {tokenomics["Liquidity Pool "]}
              </span>
              <br />
              <br />
              <span
                style={{ color: "white",  }}
              >
                {" "}
                Symbol:{" "}
              </span>
              <span style={{ color: "grey",fontWeight:"bolder"  }}>
                {tokenomics["Symbol "]}
              </span>
              <br />
              <br />
              <span
                style={{ color: "white",  }}
              >
                {" "}
                Chain :{" "}
              </span>
              <span style={{ color: "grey", fontWeight:"bolder" }}>
                {tokenomics["Chain "]}
              </span>
              <br />
              <br />
              <span
                style={{ color: "white",  }}
              >
                {" "}
                Coin Address :{" "}
              </span>
              <span style={{ color: "grey",fontWeight:"bolder" }}>
                {tokenomics["Coin Address "]}
              </span>
            </div>
          </div>
        </div>

        {/* <div className="greeting-image-div"> */}
        {/* {illustration.animated ? (
              <DisplayLottie animationData={landingPerson} />
            ) : (
              <img
                alt="man sitting on table"
                src={require("../../assets/images/manOnTable.svg")}
              ></img>
            )} */}
        <div className="col-md-6 col-sm-12 m-auto text-center ">
          <img src="./6.png" width={"60%"} className="mx-auto " />
        </div> 
      </div>
    </Fade>
  );
}
