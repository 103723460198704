import React, {useContext} from "react";
import {Fade} from "react-reveal";
import emoji from "react-easy-emoji";
import "./Greeting.scss";
import landingPerson from "../../assets/lottie/game-icon";
import DisplayLottie from "../../components/displayLottie/DisplayLottie";
import SocialMedia from "../../components/socialMedia/SocialMedia";
import Button from "../../components/button/Button";

import {illustration, greeting,skillsSection} from "../../portfolio";
import StyleContext from "../../contexts/StyleContext";

export default function ProjecFacts() {
  const {isDark} = useContext(StyleContext);
  if (!greeting.displayGreeting) {
    return null;
  }
  return (
    <Fade bottom duration={1000} distance="40px">
   <div className="row" style={{background:"#82482C"}}>


        <div className="col-md-6 col-sm-12  order-2 m-auto"  style={{font:"Neue Machina"}}>
          
         
            <div style={{padding:"50px"}}  >
              <h1
                style={{fontWeight:"bolder", color:"red"}}
               
              >
                {" "}
                <span style={{color:"white",fontStyle:"italic",fontFamily:"fresca",fontSize:"5rem"}}>About $CATZ</span>
                 {/* <span className="wave-emoji">{emoji("🐸")}</span>  */}
              </h1>
              <div className="m-auto">
              {skillsSection.skills.map((skills, i) => {
                return (
                  <p
                    key={i}
                    style={{color:"white",fontFamily:"fresca"}}
                  >
                    {skills}
                  </p>
                );
              })}
              </div>
        
    

        
            </div>
  
          </div>
    
          
          {/* <div className="greeting-image-div"> */}
            {/* {illustration.animated ? (
              <DisplayLottie animationData={landingPerson} />
            ) : (
              <img
                alt="man sitting on table"
                src={require("../../assets/images/manOnTable.svg")}
              ></img>
            )} */}
            <div className="col-md-6 col-sm-12 m-auto text-center order-1">
              <img src="./7.png" width={"60%"} className="mx-auto "/>
            </div>


            </div>
        
     
    </Fade>
  );
}
