import React, {useContext} from "react";
import {Fade} from "react-reveal";
import emoji from "react-easy-emoji";
import "./Greeting.scss";
import landingPerson from "../../assets/lottie/game-icon";
import DisplayLottie from "../../components/displayLottie/DisplayLottie";
import SocialMedia from "../../components/socialMedia/SocialMedia";
import Button from "../../components/button/Button";

import {illustration, greeting} from "../../portfolio";
import StyleContext from "../../contexts/StyleContext";

export default function Greeting() {
  const {isDark} = useContext(StyleContext);
  if (!greeting.displayGreeting) {
    return null;
  }
  return (
    <Fade bottom duration={1000} distance="40px">
   <div className="row" style={{background:"#82482C"}}>

   <div className="col-md-12 col-sm-12 m-auto text-center">
              <img src="./2.png" width={"40%"} className="m-0 "/>
            </div>
        <div className="col-md-12 col-sm-12 m-0 text-center"  style={{font:"fresca"}}>
      
         
            <div style={{padding:"50px"}} className="text-center m-auto">
              <h1
                style={{fontWeight:"bolder", color:"red"}}
               
              >
                {" "}
                <span style={{color:"white",fontStyle:"italic",fontFamily:"fresca",fontSize:"5rem"}}>Save your Cat</span>
                {" "}
                {/* <span style={{color:"#18a12d",fontFamily:"La Macchina"}}>No Coin</span> */}
                 {/* <span className="wave-emoji">{emoji("🐸")}</span>  */}
              </h1>
              <p
               
                style={{color:"white",width:"60%",margin:"auto",padding:"15px",fontFamily:"fresca"}}
              >
                {greeting.subTitle}
              </p>
              <SocialMedia />

              <div
               style={{fontWeight:"bolder"}}
            
             >
                <a href="https://raydium.io/swap/?inputMint=sol&outputMint=brb8miugndFGFdtMAf21W8pFvDKryy9NenLd1fSpump" style={{textDecoration:"none"}} target="_blank" class="myButton">Buy Now</a>

         
             </div>
              {/* <div className="button-greeting-div">
                <Button text="Contact me" href="#contact" />
                {greeting.resumeLink && (
                  <Button
                    text="See my resume"
                    newTab={true}
                    href={greeting.resumeLink}
                  />
                )}
              </div> */}
            </div>
  
          </div>
    
          
          {/* <div className="greeting-image-div"> */}
            {/* {illustration.animated ? (
              <DisplayLottie animationData={landingPerson} />
            ) : (
              <img
                alt="man sitting on table"
                src={require("../../assets/images/manOnTable.svg")}
              ></img>
            )} */}
          


            </div>
        
     
    </Fade>
  );
}
